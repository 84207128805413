import React, { useState } from "react";

const DateInput = ({ date, onChange }) => {
  let defaultDate = new Date();
  if (date != null) {
    defaultDate = new Date(date);
  }

  const [day, setDay] = useState(defaultDate.getDate());
  const [month, setMonth] = useState(defaultDate.getMonth());
  const [year, setYear] = useState(defaultDate.getFullYear());

  const onDayChange = (evt) => {
    const newDay = Number(evt.target.value);
    setDay(newDay);
    onDateChange(year, month, newDay);
  }

  const onMonthChange = (evt) => {
    const newMonth = Number(evt.target.value);
    setMonth(newMonth);
    onDateChange(year, newMonth, day);
  }

  const onYearChange = (evt) => {
    const newYear = Number(evt.target.value);
    setYear(newYear);
    onDateChange(newYear, month, day);
  }

  const onDateChange = (year, month, day) => {
    if (onChange != null && typeof(onChange) === 'function') {
      const newDate = new Date(year, month, day);
      const newDateStr = newDate.toISOString().slice(0, 10);
      onChange(newDateStr);
    }
  }

  return (
    <div className="dateinput inputrow">
      <input className="day" type="number" defaultValue={day} onChange={onDayChange} />
      <select className="month" defaultValue={month} onChange={onMonthChange}>
        <option value="0">January</option>
        <option value="1">February</option>
        <option value="2">March</option>
        <option value="3">April</option>
        <option value="4">May</option>
        <option value="5">June</option>
        <option value="6">July</option>
        <option value="7">August</option>
        <option value="8">September</option>
        <option value="9">October</option>
        <option value="10">November</option>
        <option value="11">December</option>
      </select>
      <input className="year" type="number" defaultValue={year} onChange={onYearChange} />
    </div>
  )
}

export default DateInput;
