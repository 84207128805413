import React from "react";

export const ProfileBlock = ({ profile }) => {
  let capabilities = 'None';
  if(profile.capabilities && profile.capabilities.length > 0) {
    capabilities = profile.capabilities.join(', ');
  }

  let sites = 'None';
  if(profile.sites && profile.sites.length > 0) {
    sites = profile.sites.map(s => s.name).join(', ')
  }

  return (
    <div className="entity">
      <label htmlFor="profile-setup-required">Setup Required</label>
      <p id="profile-setup-required">{(profile.setup_required || false).toString()}</p>
      {profile.account && (
        <React.Fragment>
          <label htmlFor="profile-account-email">Account Email</label>
          <p id="profile-account-email">{profile.account.email}</p>
        </React.Fragment>
      )}
      {profile.person && (
        <React.Fragment>
          <label htmlFor="profile-person-name">Person Name</label>
          <p id="profile-person-name">{profile.person.name}</p>
        </React.Fragment>
      )}
      {profile.capabilities && (
        <React.Fragment>
          <label htmlFor="profile-capabilities">Capabilities</label>
          <p id="profile-capabilities">{capabilities}</p>
        </React.Fragment>        
      )}
      {profile.company && (
        <React.Fragment>
          <label htmlFor="profile-company-name">Company Name</label>
          <p id="profile-company-name">{profile.company.name}</p>
        </React.Fragment>
      )}
      {profile.sites && (
        <React.Fragment>
          <label htmlFor="profile-site-names">Site Names</label>
          <p id="profile-site-names">{sites}</p>
        </React.Fragment>
      )}
    </div>
  )
}
