import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import {
  processDocument
} from './documentSlice';


export const DocumentReloadButton = ({ document, label}) => {
  const [ isLoading, setIsLoading ] = useState(false);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  return (
    <div>
      <Button
        color="danger"
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          dispatch(processDocument({
            getToken: getAccessTokenSilently,
            document
          })).then((action) => {
            setIsLoading(false);
            return action
          })
        }}
      >{label}</Button>
    </div>

  )
}