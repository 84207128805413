import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert, Table } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSites,
  selectSiteById,
  selectSiteIds
} from "./siteSlice";
import { TableEntry } from '../../components/TableEntry';
import { LoadingBlock } from '../../components/LoadingBlock';

let SiteRow = ({ id }) => {
  const site = useSelector(state => selectSiteById(state, id));

  return (
    <tr key={site._id}>
      <td>
        <Link to={`/sites/${site._id}`}><TableEntry value={site.name}/></Link>
      </td>
      <td><TableEntry value={site?.type?.title}/></td>
      <td><TableEntry value={site?.location?.display_name}/></td>
      <td>
        {site.company != null && (
          <Link to={`/companies/${site.company._id}`}>{site.company.name || '🔶'}</Link>
        )}
        {site.company == null && (
          <span>🔶</span>
        )}
      </td>
    </tr>
  )
}

export const SiteListComponent = () => {
  const dispatch = useDispatch();
  const orderedSiteIds = useSelector(selectSiteIds);

  const sitesStatus = useSelector(state => state.sites.status);
  const error = useSelector(state => state.sites.error);

  const {
    getAccessTokenSilently
  } = useAuth0();


  useEffect(() => {
    if(sitesStatus === 'idle') {
      dispatch(fetchSites({
        getToken: getAccessTokenSilently
      }))
    }
  }, [sitesStatus, dispatch, getAccessTokenSilently]);

  let content;

  if(sitesStatus === 'loading') {
    content = <LoadingBlock />
  } else if(sitesStatus === 'succeeded') {
    content = (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Location</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
        {orderedSiteIds.map((id) => (
          <SiteRow key={id} id={id} />
        ))}
        </tbody>
      </Table>
    );
  } else if(sitesStatus === 'failed') {
    content = <Alert color="warning">{JSON.stringify(error)}</Alert>
  } else {
    content = <LoadingBlock text="About to start loading..." />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          <h2>Sites</h2>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(SiteListComponent, {
  onRedirecting: () => <Loading />,
});
