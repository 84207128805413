import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import CompanyList from "./views/companies/CompanyList";
import CompanyPage from "./views/companies/CompanyPage";
import SiteList from "./views/sites/SiteList";
import SitePage from './views/sites/SitePage';
import PersonList from "./views/people/PersonList";
import PersonPage from './views/people/PersonPage';
import MeterList from './views/meters/MeterList';
import MeterPage from './views/meters/MeterPage';
import FeedList from './views/feeds/FeedList';
import FeedPage from './views/feeds/FeedPage';
import ReportPage from './views/feeds/ReportPage';
import ExtractsPage from './views/extracts/ExtractsPage';

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const {
    isLoading,
    error,
    isAuthenticated
  } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          {isAuthenticated && (<Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/companies" component={CompanyList} />
            <Route exact path="/companies/:id" component={CompanyPage} />
            <Route exact path="/sites" component={SiteList} />
            <Route exact path="/sites/:id" component={SitePage} />
            <Route exact path="/people" component={PersonList} />
            <Route exact path="/people/:id" component={PersonPage} />
            <Route exact path="/meters" component={MeterList} />
            <Route exact path="/meters/:id" component={MeterPage} />
            <Route exact path="/feeds" component={FeedList} />
            <Route exact path="/feeds/:id" component={FeedPage} />
            <Route exact path="/feeds/:id/reports/:rid" component={ReportPage} />
            <Route exact path="/extracts" component={ExtractsPage} />
          </Switch>)}
          {!isAuthenticated && (
            <div><p>Please login!</p></div>
          )}
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
