import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import {
  fetchDocument
} from './documentSlice';


export const DocumentDownloadButton = ({ reference, label}) => {
  const [ downloadUrl, setDownloadUrl ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);

  const downloadLink = useRef(null);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  const onFileReceived = (url) => {
    setDownloadUrl(url);
    downloadLink.current.click();
    setIsLoading(false);
  }

  return (
    <div>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          dispatch(fetchDocument({
            getToken: getAccessTokenSilently,
            reference,
            onFileReceived
          }))
        }}
      >{label}</Button>
      <a style={{display: "none"}}
        download={reference.key}
        href={downloadUrl}
        ref={downloadLink}
      >Download it</a>
    </div>

  )
}