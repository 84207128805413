import React from "react";
import { Table } from "reactstrap";
import { Timestamp } from '../../components/Timestamp';
import { ElapsedTime } from "../../components/ElapsedTime";
import { CollapsibleTable } from "../../components/Collapsible";
import { DocumentDownloadButton } from "../documents/DocumentDownloadButton";

export const ReportErrorBlock = ({ error, prefix }) => {
  const itemRow = (key, item) => (
    <tr key={key}>
      <td>{(item && item.type) || ''}</td>
      <td>{(item && item.line) || ''}</td>
      <td>{(item && item.column) || ''}</td>
      <td><Timestamp timestamp={(item && item.timestamp)} format="shortutc" /></td>
      <td>{(item && item.value) || ''}</td>
    </tr>
  )
  const causeRow = (key, cause) => (
    <tr key={key}>
      <td>{(cause && cause.from) || ''}</td>
      <td>{(cause && cause.to) || ''}</td>
      <td>{(cause && cause.cause && cause.cause.name) || ''}</td>
      <td>{(cause && cause.cause && cause.cause.message) || ''}</td>
    </tr>
  )
  const sliceRow = (key, slice) => (
    <tr key={key}>
      <td>{slice.n}</td>
      <td>
        <CollapsibleTable
          items={slice.r}
          headers={['Timestamp', 'Value']}
          row={(k, r) => (
            <tr key={k}>
              <td><Timestamp timestamp={r.t} format="shortutc" /></td>
              <td>{r.v}</td>
            </tr>
          )}
        />
      </td>
    </tr>
  )

  return (
    <div className="entity">
      <label htmlFor={`error-${prefix}-type`}>Type</label>
      <p id={`error-${prefix}-type`}>{error.type}</p>
      {error.items && error.items.length > 0 && (
        <React.Fragment>
          <label htmlFor={`error-${prefix}-items`}>Items</label>
          <CollapsibleTable
            items={error.items}
            headers={['Type', 'Line', 'Column', 'Timestamp', 'Value']}
            row={itemRow}
          />
        </React.Fragment>
      )}
      {error.slice && (
        <React.Fragment>
          <label htmlFor={`error-${prefix}-slice`}>Slice</label>
          <CollapsibleTable
            items={error.slice.n.map((n, i) => ({
              n,
              r: error.slice.r.map(r => ({
                t: r.t,
                v: r.v[i]
              }))
            }))}
            headers={['Name', 'Records']}
            row={sliceRow}
          />
        </React.Fragment>
      )}
      {error.causes && error.causes.length > 0 && (
        <React.Fragment>
          <label htmlFor={`error-${prefix}-causes`}>Causes</label>
          <CollapsibleTable
            items={error.causes}
            headers={['From', 'To', 'Name', 'Message']}
            row={causeRow}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export const ReportDiffBlock = ({ diff }) => {
  const diffLabel = (list) => {
    if(list == null || list.length <= 0) {
      return 'None'
    }
    return `${list.length} records`;
  }
  const createdLabel = (diff) => {
    return diffLabel(diff && diff.created);
  }
  const updatedLabel = (diff) => {
    return diffLabel(diff && diff.updated);
  }

  return (
    <div>
      <p>{`${(diff.n && diff.n.length) || 0} metrics`}</p>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
        {diff.n.map((n, i) => (
          <tr key={n}>
            <td>{n}</td>
            <td>{createdLabel(diff.diff[i])}</td>
            <td>{updatedLabel(diff.diff[i])}</td>
            <td>
              {diff.e && i < diff.e.length && diff.e[i] && (
                <ReportErrorBlock error={diff.e[i]} prefix={`diff-${i}`} />
              )}
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
}

export const ReportBlock = ({ report }) => {
  return (
    <div className="entity">
      <label htmlFor="report-started-at">Started At</label>
      <p id="report-started-at"><Timestamp timestamp={report.startedAt} /></p>
      <label htmlFor="report-finished-at">Finished At</label>
      <p id="report-finished-at"><Timestamp timestamp={report.finishedAt} /></p>
      <label htmlFor="report-elapsed">Elapsed Time</label>
      <p id="report-elapsed"><ElapsedTime from={report.startedAt} to={report.finishedAt} /></p>
      {report.document && (
        <React.Fragment>
          <label htmlFor="report-document">Document</label>
          <DocumentDownloadButton reference={report.document} label="Download" />
        </React.Fragment>
      )}
      <label htmlFor="report-diff">Diff</label>
      <ReportDiffBlock diff={report.sd} />
      {report.se && (
        <React.Fragment>
          <label htmlFor="report-error">Error</label>
          <ReportErrorBlock id="report-error" error={report.se} prefix="se" />
        </React.Fragment>
      )}
    </div>
  )
}
