import { getApiConfig } from "../config";

const apiConfig = getApiConfig() || {};
const apiDomain = apiConfig.domain || "http://localhost:8000";

function buildUrl(path) {
  const url = new URL(path, apiDomain);
  return url.toString();
}

async function fetchData(request) {
  let data
  try {
    const response = await request();
    data = await response.json();
    if (response.ok) {
      return Promise.resolve(data);
    }
    return Promise.reject(new Error(response.statusText))
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

async function processResponse(response, onResult, onError) {
  if(response.status >= 200 && response.status < 300) {
    const responseData = await response.json();
    onResult(responseData);
  } else {
    const responseData = await response.text();
    onError({
      status: response.status,
      text: responseData
    })
  }
}

export const client = {
  getCompanies: async (token) => {
    return fetchData(() => fetch(buildUrl('/companies'), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getCompanyById: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/companies/${id}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getSites: async (token) => {
    return fetchData(() => fetch(buildUrl('/sites'), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getSiteById: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/sites/${id}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getPeople: async (token) => {
    return fetchData(() => fetch(buildUrl('/people'), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getPersonById: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/people/${id}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  listDocuments: async(token, bucket, continuation) => {
    let path = `/documents/${bucket}`;
    if(continuation != null) {
      path = path + `?continuation=${continuation}`
    }
    return fetchData(() => fetch(buildUrl(path), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getDocument: async(token, reference) => {
    const { bucket, key } = reference;
    return fetch(buildUrl(`/documents/${bucket}/${key}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getMeters: async (token) => {
    return fetchData(() => fetch(buildUrl('/meters'), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getMeterById: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/meters/${id}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  patchMeterById: async(token, id, patch) => {
    return fetchData(() => fetch(buildUrl(`/meters/${id}`), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(patch)
    }))
  },
  getSliceByMeterAndMetricId: async(token, meterId, metricId, from, to, period) => {
    return fetchData(() => fetch(buildUrl(`/meters/${meterId}/metrics/${metricId}/${from}/${to}/${period}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getSliceBySiteAndSupplyId: async(token, siteId, supplyId, from, to, period) => {
    return fetchData(() => fetch(buildUrl(`/sites/${siteId}/supplies/${supplyId}/${from}/${to}/${period}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getFeeds: async (token) => {
    return fetchData(() => fetch(buildUrl('/feeds'), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  getFeedById: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/feeds/${id}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  },
  postFileToFeed: async(token, id, file) => {
    let contentType = file.type;
    if(contentType === 'application/vnd.ms-excel' && file.name.endsWith('.csv')) {
      // this a hack as the OS reports CSV files as Excel ones
      contentType = 'text/csv'
    }
    return fetchData(() => fetch(buildUrl(`/feeds/${id}`), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType
      },
      body: file
    }));
  },
  getReportsByFeedId: async(token, id) => {
    return fetchData(() => fetch(buildUrl(`/feeds/${id}/reports`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }))
  },
  processDocument: async(token, bucket, key) => {
    return fetchData(() => fetch(buildUrl(`/etl/process`), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bucket,
        key
      })
    }))
  },
  getProfileByAccountId: async(token, accountId) => {
    return fetchData(() => fetch(buildUrl(`/profiles/${accountId}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }))
  },
  getSliceBySiteAndSelector: async(token, siteId, selector, from, to, period) => {
    return fetchData(() => fetch(buildUrl(`/sites/${siteId}/supplies/${selector}/${from}/${to}/${period}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }
}

export {
  buildUrl,
  processResponse,
}
