import { configureStore } from '@reduxjs/toolkit';
import companyReducer from './views/companies/companySlice';
import siteReducer from './views/sites/siteSlice';
import personReducer from './views/people/personSlice';
import consentReducer from './views/consents/consentSlice';
import meterReducer from './views/meters/meterSlice';
import feedReducer from './views/feeds/feedSlice';
import reportReducer from './views/feeds/reportSlice';
import extractsReducer from './views/extracts/extractsSlice';

import { client } from './api/client';

export default configureStore({
  reducer: {
    companies: companyReducer,
    sites: siteReducer,
    people: personReducer,
    consents: consentReducer,
    meters: meterReducer,
    feeds: feedReducer,
    reports: reportReducer,
    extracts: extractsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { client }
      }
    })
});
