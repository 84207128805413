import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const feedAdapter = createEntityAdapter({
  selectId: (feed) => feed._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const initialState = feedAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchFeeds = createAsyncThunk(
  'feeds/fetchAll',
  async ({ getToken }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getFeeds(token);
    return response.feeds;
  }
);

export const fetchFeedById = createAsyncThunk(
  'feeds/fetchById',
  async ({ getToken, id }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getFeedById(token, id);
    return response;
  }
)

export const feedSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFeeds.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFeeds.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      feedAdapter.upsertMany(state, action.payload);
    },
    [fetchFeeds.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchFeedById.fulfilled]: feedAdapter.addOne
  }
});

export default feedSlice.reducer;

export const {
  selectAll: selectAllFeeds,
  selectById: selectFeedById,
  selectIds: selectFeedIds
} = feedAdapter.getSelectors(state => state.feeds);
