export const Timestamp = ({ timestamp, format }) => {
  if(timestamp == null) {
    return null;
  }
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const tsDate = new Date(timestamp);
  const pad = (value, pad) => {
    return value.toString().padStart(pad || 2, '0');
  }
  const formats = {
    default: (d) => `${d.getFullYear()} ${months[d.getMonth()]} ${d.getDate()} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`,
    short: (d) => `${d.getFullYear()}-${pad(d.getMonth()+1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}`,
    shortutc: (d) => `${d.getUTCFullYear()}-${pad(d.getUTCMonth()+1)}-${pad(d.getUTCDate())} ${pad(d.getUTCHours())}:${pad(d.getUTCMinutes())} Z`
  }
  let ff = formats.default;
  if(format != null && format in formats) {
    ff = formats[format];
  }
  const displayDate = ff(tsDate);
  const titleDate = tsDate.toISOString();
  return (
    <span className="timestamp" title={titleDate}>{displayDate}</span>
  )
}