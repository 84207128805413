import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table } from "reactstrap";
import { fetchReportsByFeed } from './reportSlice';
import { DocumentDownloadButton } from "../documents/DocumentDownloadButton";
import { Timestamp } from "../../components/Timestamp";
import { selectReportsByFeed } from './reportSlice';

export const UploadReportList = ({ feed, label, rev }) => {
  const loadedReports = useSelector(state => selectReportsByFeed(state, feed));

  const [ reports, setReports ] = useState(loadedReports);
  const [ isLoading, setIsLoading ] = useState(false);


  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  if(feed.report !== 'save') {
    return (
      <p>Reports not saved for this feed</p>
    )
  }

  return (
    <div className={`rev-${rev}`}>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          dispatch(fetchReportsByFeed({
            getToken: getAccessTokenSilently,
            feed
          })).then(
            ({ payload }) => {
              const sortedPayload = payload.sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));
              setReports(sortedPayload);
              setIsLoading(false);
            }
          )
        }}
      >{label}</Button>
      {reports != null && reports.length > 0 && (
        <Table>
          <thead>
            <tr><th>Started At</th><th>Finished At</th><th>Changed</th><th>Error</th><th>Document</th></tr>
          </thead>
          <tbody>
            {reports.map(report => (
              <tr key={report._id}>
                <td><Link to={`/feeds/${feed._id}/reports/${report._id}`}><Timestamp timestamp={report.startedAt} /></Link></td>
                <td><Timestamp timestamp={report.finishedAt} /></td>
                <td>{(report.sd && report.sd.n && report.sd.n.length) || 0}</td>
                <td>{(report.se && report.se.type) || ''}</td>
                <td>{(report.document && <DocumentDownloadButton reference={report.document} label="Download" />)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )

}