const mergeSliceRecords = (records) => {
  const result = [];
  // collect max lengths of v entries to fill in with null when empty
  const vlengths = records.map(
    lst => {
      if(lst.length === 0) {
        return 0;
      }
      return Math.max(...lst.map(rec => (rec && rec.v && rec.v.length) || 0))
    }
  );
  let tails = records;
  let maxlen = Math.max(...tails.map(s => s.length));
  while (maxlen > 0) {
    // Get the heads for all sets of records if they exist
    const candidates = tails.map((s) => {
      if (s.length > 0) {
        return s[0];
      }
      return null;
    });
    // Calculate the minimal timestamp
    const mint = Math.min(...candidates.filter(c => c !== null && c.t).map(c => c.t));
    // Filter all candidates to only keep the ones that have the minimal timestamp
    const selected = candidates.map((s) => {
      if (s && s.t === mint) {
        return s;
      }
      return null;
    });
    // Create a selected record by merging all selected entries and ensuring the
    // correct number of items in v
    const selectedRecord = selected.reduce((acc, r, i) => {
      const vlen = vlengths[i];
      let newv = [];
      if(vlen > 0) {
        newv = Array(vlen).fill(null).map(
          (_, j) => r && r.v && r.v.length > j && r.v[j]
        );
      }
      return {
        t: acc.t || (r && r.t),
        ts: acc.ts || (r && r.ts),
        v: [...acc.v, ...newv]
      };
    }, {
      t: null,
      ts: null,
      v: []
    });
    result.push(selectedRecord);
    // Remove all selected records from the remaining ones by slicing if a
    // record has been kept in the selected list
    tails = tails.map((s, i) => {
      if (selected[i] !== null) {
        return s.slice(1);
      }
      return s;
    });
    maxlen = Math.max(...tails.map(s => s.length));
  }
  return result;
};

const mergeSlicePair = (s1, s2) => ({
  n: [...s1.n, ...s2.n],
  m: [...s1.m, ...s2.m],
  u: [...s1.u, ...s2.u],
  p: s1.p,
  r: mergeSliceRecords([s1.r, s2.r]),
  meta: [...s1.meta, ...s2.meta]
});


class DataPath {
  constructor(path) {
    if (Array.isArray(path)) {
      this.path = path;
    } else {
      this.path = path.toString().split('/');
    }
  }

  length() {
    return this.path.length;
  }

  segments() {
    return this.path;
  }

  segment(i) {
    return i >= 0 && i < this.path.length && this.path[i];
  }

  child(subpath) {
    if (Array.isArray(subpath)) {
      return new DataPath([...this.path, ...subpath]);
    }
    return new DataPath([...this.path, subpath.toString()].join('/'));
  }

  parent() {
    if (this.isRoot()) {
      return null;
    }
    return new DataPath(this.path.slice(0, this.path.length - 1));
  }

  isRoot() {
    return (this.path.length === 0);
  }

  isAncestorOf(other) {
    return (other.path.length > this.path.length)
      && (this.path.every(
        (p, i) => p === other.path[i]
      ));
  }

  shift() {
    return new DataPath(this.path.slice(1));
  }

  isDescendantOf(other) {
    return other.isAncestorOf(this);
  }

  toString() {
    return this.path.join('/');
  }
}


class DataSeries {
  constructor(series) {
    this.series = series;
  }

  name() {
    return this.series.n;
  }

  path() {
    return new DataPath(this.name());
  }

  unit() {
    return this.series.u;
  }

  measure() {
    return this.series.m;
  }

  records() {
    return this.series.r;
  }

  record(timestamp) {
    return this.series.r.find(r => r.t === timestamp || r.ts === timestamp);
  }

  value(timestamp) {
    const rec = this.record(timestamp);
    return rec != null ? rec.v : null;
  }

  meta() {
    return this.series.meta;
  }

  length() {
    return this.series.r.length;
  }

  toDataFrame() {
    return new DataFrame({
      n: [this.series.n],
      m: [this.series.m],
      u: [this.series.u],
      p: this.series.p,
      r: this.series.r.map(r => ({
        ...r,
        v: [r.v]
      })),
      meta: [this.series.meta]
    });
  }
}


class DataFrame {
  constructor(slice) {
    if(slice == null) {
      this.slice = {
        n: [],
        m: [],
        u: [],
        p: null,
        r: [],
        meta: []
      }
    } else {
      const sliceWidth = (slice.n && slice.n.length) || 0;
      this.slice = {
        ...slice,
        m: slice.m || new Array(sliceWidth).fill(null),
        u: slice.u || new Array(sliceWidth).fill(null),
        meta: slice.meta || new Array(sliceWidth).fill(null),
        r: slice.r || []
      };
    }
  }

  static fromSeriesArray(series) {
    return new DataFrame(series.reduce(
      (acc, s) => {
        if (acc == null) {
          return s.toDataFrame();
        }
        return acc.append(s);
      },
      null
    ));
  }

  names() {
    return this.slice.n;
  }

  paths() {
    return this.names().map(n => new DataPath(n));
  }

  units() {
    return this.slice.u;
  }

  measures() {
    return this.slice.m;
  }

  records() {
    return this.slice.r;
  }

  record(timestamp) {
    return this.slice.r.find(r => r.t === timestamp || r.ts === timestamp);
  }

  values(timestamp) {
    const rec = this.record(timestamp);
    return rec != null ? rec.v : this.slice.n.map(() => null);
  }

  meta() {
    return this.slice.meta;
  }

  length() {
    return this.slice.r.length;
  }

  width() {
    return this.slice.n.length;
  }

  findSeriesIndex(name) {
    return this.slice.n.findIndex(n => n === name.toString());
  }

  findSeries(name) {
    const idx = this.findSeriesIndex(name);
    if (idx < 0) {
      return null;
    }
    return this.getSeries(idx);
  }

  getSeries(index) {
    return new DataSeries({
      n: this.slice.n[index],
      m: this.slice.m[index],
      u: this.slice.u[index],
      p: this.slice.p,
      r: this.slice.r.map(r => ({
        ...r,
        v: r.v[index]
      })),
      meta: this.slice.meta[index]
    });
  }

  concat(other) {
    return new DataFrame(mergeSlicePair(this.slice, other.slice));
  }

  append(series) {
    return new DataFrame({
      n: [...this.slice.n, series.name()],
      m: [...this.slice.m, series.measure()],
      u: [...this.slice.u, series.unit()],
      p: this.slice.p,
      r: mergeSliceRecords([this.slice.r, series.records().map(r => ({
        ...r,
        v: [r.v]
      }))]),
      meta: [...this.slice.meta, series.meta()]
    });
  }

  merge(dataframe) {
    return new DataFrame(
      mergeSlicePair(this.slice, dataframe.slice)
    )
  }

  mapToArray(mapper) {
    const w = this.width();
    const result = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < w; i++) {
      const series = this.getSeries(i);
      result.push(mapper(series, i, this));
    }
    return result;
  }

  map(mapper) {
    const mappedSeries = this.mapToArray(mapper);
    return DataFrame.fromSeriesArray(mappedSeries);
  }

  filter(filter) {
    if(this.width() === 0) {
      return this;
    }
    const flags = this.mapToArray(filter);
    const result = flags.reduce((acc, flag, i) => {
      if (flag) {
        const series = this.getSeries(i);
        if (acc == null) {
          return series.toDataFrame();
        }
        return acc.append(series);
      }
      return acc;
    }, null);
    if(result == null) {
      return new DataFrame({
        n: [],
        m: [],
        u: [],
        p: this.slice.p,
        r: [],
        meta: []
      })
    }
    return result;
  }

  reduce(reducer, initial) {
    const w = this.width();
    let result = initial;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < w; i++) {
      const series = this.getSeries(i);
      result = reducer(result, series, i, this);
    }
    return result;
  }

  groupBy(groupKey) {
    return this.reduce(
      (acc, series) => {
        const key = groupKey(series);
        const df = acc[key];
        if (df == null) {
          acc[key] = series.toDataFrame();
        } else {
          acc[key].append(series);
        }
        return acc;
      },
      {}
    );
  }
}

export {
  DataPath,
  DataSeries,
  DataFrame
};
