import { ConsentContentComponent } from "../consents/ConsentContent";

export const CompanyContentComponent = ({ company }) => {
  if(company == null) {
    return null;
  }

  const loa = company?.consent?.loa;

  let loaContent = (
    <p>No LOA</p>
  );

  if(loa != null) {
    loaContent = (
      <ConsentContentComponent consent={company?.consent?.loa} />
    )
  }

  let featuresContent = (
    <p>None</p>
  )

  if(company.features && company.features.length > 0) {
    featuresContent = (
      <p>{company.features.join(', ')}</p>
    )
  }

  return (
    <div className="entity">
      <label htmlFor="company-name">Name</label>
      <p id="company-name">{company.name}</p>
      <label htmlFor="company-features">Extra Features</label>
      {featuresContent}
      <label htmlFor="company-loa">Letter of Authority</label>
      {loaContent}
    </div>
  )
}
