import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFeedById,
  selectFeedById
} from "./feedSlice";
import { postFileToFeed } from "./reportSlice";
import { ReportBlock } from "./UploadReport";
import { UploadReportList } from "./UploadReportList";
import { DocumentList } from '../documents/DocumentList';

const FeedUploadComponent = ({ feed, onFulfilled }) => {
  const [file, setFile] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ report, setReport ] = useState(null);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  const inputAccept = (feed) => {
    const contentTypes = feed.contentType || [];
    return contentTypes.reduce((acc, t) => {
      if(t === 'application/json') {
        acc.push('.json');
      }
      if(t === 'text/plain') {
        acc.push('.txt')
      }
      if(t === 'text/csv') {
        acc.push('.csv')
      }
      acc.push(t);
      return acc;
    }, []).join(',')
  }

  const loadFile = () => {
    setIsLoading(true);
    dispatch(postFileToFeed({
      getToken: getAccessTokenSilently,
      id: feed._id,
      file
    })).then(action => {
      setReport(action.payload);
      setIsLoading(false);
      if(onFulfilled && typeof(onFulfilled) === 'function') {
        onFulfilled()
      }
      return action;
    })
  }

  return (
    <div>
      <div className="inputrow">
        <input
          type="file"
          multiple={false}
          accept={inputAccept(feed)}
          onChange={evt => setFile(evt.target.files[0])}
        />
        <Button
          color="primary"
          disabled={file == null || isLoading === true}
          onClick={() => loadFile()}
        >Upload</Button>
      </div>
      {file != null && isLoading === true && (
        <p className="loader">Loading...</p>
      )}
      {report != null && isLoading === false && (
        <ReportBlock report={report} />
      )}
    </div>
  )
}

export const FeedPageComponent = ({ match }) => {
  const [lastLoadDate, setLastLoadDate] = useState(new Date());

  const { id } = match.params;
  const feed = useSelector(state => selectFeedById(state, id));

  const dispatch = useDispatch();

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if(feed == null) {
      dispatch(fetchFeedById({
        getToken: getAccessTokenSilently,
        id
      }))
    }
  }, [feed, id, dispatch, getAccessTokenSilently]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          {feed == null && (
            <div>
              <h2><Link to="/feeds">Feeds</Link></h2>
              <p>Feed with ID {id} is loading</p>
            </div>
          )}
          {feed != null && (
            <div>
              <h2><Link to="/feeds">Feeds</Link> &gt; {feed.name}</h2>
              <div className="entity">
                <label htmlFor="feed-name">Name</label>
                <p id="feed-name">{feed.name}</p>
                <label htmlFor="feed-content-types">Content Type</label>
                <p id="feed-content-types">{(feed.contentType || []).join(', ')}</p>
                <label htmlFor="feed-upload">Upload</label>
                <FeedUploadComponent feed={feed} onFulfilled={() => setLastLoadDate(new Date())} />
                {feed.documents != null && (
                  <React.Fragment>
                    <label htmlFor="feed-documents">Documents</label>
                    <DocumentList bucket={feed.documents.bucket} label="Fetch" rev={lastLoadDate.toISOString()} />
                  </React.Fragment>
                )}
                <label htmlFor="feed-reports">Reports</label>
                <UploadReportList feed={feed} label="Fetch" />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(FeedPageComponent, {
  onRedirecting: () => <Loading />,
});
