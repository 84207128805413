import React, { useState } from "react";
import { Button, Table } from "reactstrap";

export const Toggle = ({ label, expanded, onChange, color }) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  const createLabel = () => {
    const arrow = (isExpanded === true && '🔺') || '🔻';
    if(label == null || label === '') {
      return arrow;
    }
    return `${label} ${arrow}`;
  }

  const onClick = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    if(onChange && typeof(onChange) === 'function') {
      onChange(newState)
    }
  }

  return (
    <Button
      className="toggle"
      color={color || 'primary'}
      onClick={onClick}
    >{createLabel()}</Button>
  )
}

export const CollapsibleContent = ({ expanded, summary, content }) => {
  if(expanded === true) {
    return content || null;
  }
  return summary || null;
}

export const CollapsibleBlock = ({ expanded, summary, content, toggleLabel, toggleColor }) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  return (
    <div className="collapsible">
      <Toggle label={toggleLabel} expanded={isExpanded} onChange={(state) => setIsExpanded(state)} color={toggleColor} />
      <CollapsibleContent expanded={isExpanded} summary={summary} content={content} />
    </div>
  )
}

export const CollapsibleTable = ({ expanded, items, headers, row, toggleLabel, toggleColor }) => {
  const summary = (
    <p className="content">{`${items.length} items`}</p>
  )

  const content = (
    <Table className="content">
      <thead>
        <tr>
        {headers.map((header, i) => (
          <th key={`header-${i}`}>{header}</th>
        ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => row(`item-${i}`, item))}
      </tbody>
    </Table>
  )

  if(items == null || items.length <= 0) {
    return (
      <div className="collapsible">
        <p className="content">No items</p>
      </div>
    )
  }

  return (
    <CollapsibleBlock expanded={expanded} summary={summary} content={content} toggleLabel={toggleLabel} toggleColor={toggleColor} />
  )
}
