import {
  createSlice,
  //createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  fetchCompanies,
  fetchCompanyById
} from '../companies/companySlice';

const consentAdapter = createEntityAdapter({
  selectId: (consent) => consent._id,
  sortComparer: (a, b) => a._id.localeCompare(b._id)
});

const initialState = consentAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const companySlice = createSlice({
  name: 'consents',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompanies.fulfilled]: (state, action) => {
      const consents = action.payload.map(
        company => company?.consent?.loa
      ).filter (
        consent => consent != null
      )
      consentAdapter.upsertMany(state, consents);
    },
    [fetchCompanyById.fulfilled]: (state, action) => {
      const consent = action.payload?.consent?.loa;
      if(consent != null) {
        consentAdapter.addOne(state, consent)
      }
    }
  }
});

export default companySlice.reducer;

export const {
  selectAll: selectAllConsents,
  selectById: selectConsentById,
  selectIds: selectConsentIds
} = consentAdapter.getSelectors(state => state.consents);
