import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const meterAdapter = createEntityAdapter({
  selectId: (meter) => meter._id,
  sortComparer: (a, b) => a._id.localeCompare(b._id)
});

const initialState = meterAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchMeters = createAsyncThunk(
  'meters/fetchAll',
  async ({ getToken }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getMeters(token);
    return response.meters;
  }
);

export const fetchMeterById = createAsyncThunk(
  'meters/fetchById',
  async ({ getToken, id }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getMeterById(token, id);
    return response;
  }
)

export const patchMeterById = createAsyncThunk(
  'meters/patchById',
  async ({ getToken, id, patch }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.patchMeterById(token, id, patch);
    return response;
  }
)

export const fetchSliceByIdAndMetric = createAsyncThunk(
  'meters/fetchSliceByIdAndMetric',
  async({ getToken, meterId, metricId, from, to, period }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getSliceByMeterAndMetricId(token, meterId, metricId, from, to, period);
    return response;
  }
)

export const meterSlice = createSlice({
  name: 'meters',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMeters.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchMeters.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      meterAdapter.setMany(state, action.payload);
    },
    [fetchMeters.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchMeterById.fulfilled]: meterAdapter.addOne,
    [patchMeterById.fulfilled]: meterAdapter.setOne,
  }
});

export default meterSlice.reducer;

export const {
  selectAll: selectAllMeters,
  selectById: selectMeterById,
  selectIds: selectMeterIds
} = meterAdapter.getSelectors(state => state.meters);
