import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { fetchProfileByAccountId } from "./personSlice";
import { ProfileBlock } from "./ProfileBlock";

export const AccountBlock = ({ account }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ profile, setProfile ] = useState(null);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  const loadProfile = () => {
    setIsLoading(true);
    dispatch(fetchProfileByAccountId({
      getToken: getAccessTokenSilently,
      accountId: account._id
    })).then(action => {
      setProfile(action.payload);
      setIsLoading(false);
      return action;
    })
  }

  return (
    <div className="entity">
      <label htmlFor="account-email">Email</label>
      <p id="account-email">{account.email}</p>
      <label htmlFor="account-email-verified">Email Verified</label>
      <p id="account-email-verified">{(account.email_verified || false).toString()}</p>
      <label htmlFor="account-profile">Profile</label>
      <div>
        <Button
          color="primary"
          disabled={profile != null || isLoading === true}
          onClick={() => loadProfile()}
        >Fetch</Button>
        {profile != null && isLoading === true && (
          <p className="loader">Loading...</p>
        )}
        {profile != null && isLoading === false && (
          <ProfileBlock profile={profile} />
        )}
      </div>
    </div>
  )
}
