import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table } from "reactstrap";
import {
  listDocuments
} from './documentSlice';
import { DocumentDownloadButton } from "./DocumentDownloadButton";
import { DocumentReloadButton } from "./DocumentReloadButton";
import { Timestamp } from "../../components/Timestamp";

export const DocumentList = ({ bucket, label }) => {
  const [ documents, setDocuments ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  const onFetchDocumentsClicked = () => {
    setIsLoading(true);

    const listAllDocuments = async (docs, b, c) => {
      return dispatch(listDocuments({
        getToken: getAccessTokenSilently,
        bucket: b,
        continuation: c
      })).then(
        ({ payload }) => {
          const newDocuments = [...docs, ...payload.documents]
          setDocuments(newDocuments);
          if(payload.continuation != null) {
            return listAllDocuments(newDocuments, b, payload.continuation)
          }
          setIsLoading(false);
          return null;
        }
      )
    }

    return listAllDocuments(documents || [], bucket);
  }

  return (
    <div>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={onFetchDocumentsClicked}
      >{label}</Button>
      {documents != null && documents.length > 0 && (
        <Table>
          <thead>
            <tr><th>Bucket</th><th>Key</th><th>Last Modified</th><th>Size</th><th>Download</th><th>Reload</th></tr>
          </thead>
          <tbody>
            {documents.map(document => (
              <tr key={`${document.bucket}-${document.key}`}>
                <td>{document.bucket}</td>
                <td>{document.key}</td>
                <td><Timestamp timestamp={document.lastModified} /></td>
                <td>{document.size}</td>
                <td><DocumentDownloadButton reference={document} label="Download" /></td>
                <td><DocumentReloadButton document={document} label="Reload" /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}