import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Alert, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCompanies,
  selectCompanyById,
  selectCompanyIds
} from "../companies/companySlice";
import {
  fetchSites,
  selectAllSites
} from "../sites/siteSlice";
import { LoadingBlock } from '../../components/LoadingBlock';
import DateInput from '../../components/DateInput';
import {
  fetchSliceBySiteIdAndSelector
} from './extractsSlice';
import { DataFrame } from "../../utils/dataframe";

const CompanySelector = ({ companyId, sites, onChange }) => {
  const company = useSelector(state => selectCompanyById(state, companyId))
  const companySites = sites.filter(site => site.company && site.company._id === companyId);

  const [checked, setChecked] = useState(true);
  const checkId = `checkbox-${companyId}`;

  const onCheckboxChange = () => {
    const newState = !checked;
    setChecked(newState);
    onChange(newState, companyId, companySites.map(s => s._id))
  }

  return (
    <div className="inputrow top">
      <input className="offset" type="checkbox" checked={checked} id={checkId} name={checkId} onChange={onCheckboxChange} />
      <div>
        <label htmlFor={checkId}>{company.name || '🔶'}</label>
        <ul>
          {companySites.map(site => (
            <li key={`site-${site._id}`}>{site.name || '🔶'}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const ExtractsPageComponent = () => {
  const dispatch = useDispatch();

  const orderedCompanyIds = useSelector(selectCompanyIds);
  const companiesStatus = useSelector(state => state.companies.status);

  const allSites = useSelector(selectAllSites);
  const sitesStatus = useSelector(state => state.sites.status);

  const [isLoading, setIsLoading] = useState(false);
  const [processedSoFar, setProcessedSoFar] = useState(0);
  const [totalToProcess, setTotalToProcess] = useState(1);
  const [selectedCompanyFlags, setSelectedCompanyFlags] = useState([]);
  
  const [period, setPeriod] = useState('P1D');
  const [from, setFrom] = useState('2021-01-01');
  const [to, setTo] = useState(new Date().toISOString().slice(0, 10));

  const [ downloadUrl, setDownloadUrl ] = useState(null);
  const downloadLink = useRef(null);


  const onCompanySelectorChange = (selected, companyId, siteIds) => {
    const newCoState = [...selectedCompanyFlags];
    const coIndex = orderedCompanyIds.findIndex(id => id === companyId);
    if (coIndex >= 0) {
      newCoState[coIndex] = selected;
    }
    setSelectedCompanyFlags(newCoState);
  }

  const {
    getAccessTokenSilently
  } = useAuth0();


  useEffect(() => {
    if(companiesStatus === 'idle') {
      dispatch(fetchCompanies({
        getToken: getAccessTokenSilently
      })).then(
        action => {
          const companyFlags = action.payload.map(() => true);
          setSelectedCompanyFlags(companyFlags);
        }
      )
    } else {
      const companyFlags = orderedCompanyIds.map(() => true);
      setSelectedCompanyFlags(companyFlags);
    }

    if(sitesStatus === 'idle') {
      dispatch(fetchSites({
        getToken: getAccessTokenSilently
      }))
    }
  }, [companiesStatus, orderedCompanyIds, sitesStatus, dispatch, getAccessTokenSilently]);

  const onGoButtonClicked = () => {
    const siteIds = orderedCompanyIds
      .filter((_id, i) => selectedCompanyFlags[i] === true)
      .map(id => allSites.filter(site => site.company && site.company._id === id))
      .reduce((a, b) => a.concat(b), [])
      .map(s => s._id)
    setTotalToProcess(siteIds.length);
    setProcessedSoFar(0);
    setIsLoading(true);
    
    const fetchAll = async (remaining, processed) => {
      setProcessedSoFar(processed.length);
      if(remaining.length === 0) {
        return processed;
      }
      const siteId = remaining[0];
      const tailRemaining = remaining.slice(1);
      return dispatch(fetchSliceBySiteIdAndSelector({
        getToken: getAccessTokenSilently,
        siteId,
        selector: 'energy',
        from,
        to,
        period
      })).then(
        result => {
          const newProcessed = [...processed, result];
          return fetchAll(tailRemaining, newProcessed);
        },
        err => {
          console.log(`Caught error for ${siteId}`);
          console.dir(err)
          return fetchAll(tailRemaining, processed);
        }
      )
    };

    return fetchAll(siteIds, [])
      .then(results => results.map(r => r.payload).filter(r => r != null).filter(r => r.slice != null))
      .then(results => results.map(r => new DataFrame(r.slice)))
      .then(dataframes => dataframes.map(df => df.filter(series => {
        const path = series.path();
        return (path.length() === 3 && path.segment(1) === 'energy' && path.segment(2) === 'energy')
      })))
      .then(dataframes => dataframes.reduce((acc, df) => {
        if(acc == null) {
          return df
        }
        return acc.merge(df);
      }, null))
      .then(dataframe => {
        if (dataframe == null) {
          return [['Commpany'], ['Site']]
        }
        const siteAndCoNames = dataframe.paths().map(path => {
          const siteSegment = path.segment(0);
          const [, siteId] = siteSegment.split(':');
          const site = allSites.find(s => s._id === siteId);
          return {
            site: site.name,
            company: site.company && site.company.name
          }
        });
        const companyRow = ['Company', ...siteAndCoNames.map(i => i.company)];
        const siteRow = ['Site', ...siteAndCoNames.map(i => i.site)];
        const dataRows = dataframe.records().map(r => {
          return [r.ts, ...r.v]
        });
        const allRows = [companyRow, siteRow, ...dataRows];
        return allRows
      })
      .then(rows => {
        return rows.map(
          cells => cells.map(cell => cell || '').join(',')
        ).join('\n') + '\n';
      })
      .then(content => new Blob([content], {type: 'text/csv'}))
      .then(blob => {
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
        downloadLink.current.click();
        setIsLoading(false);
        return url
      })
  }

  let content;

  if(companiesStatus === 'loading' || sitesStatus === 'loading') {
    content = <LoadingBlock />
  } else if(companiesStatus === 'succeeded' && sitesStatus === 'succeeded') {
    content = (
      <div className="entity">
        <label htmlFor="generate">Generate</label>
        <div className="inputrow">
          <Button color="primary" disabled={isLoading === true} onClick={onGoButtonClicked} >Go!</Button>
          <a style={{display: "none"}}
            download={`extract-${period}_${from}_${to}.csv`}
            href={downloadUrl}
            ref={downloadLink}
          >Download it</a>
          {isLoading === true && (
            <p>{processedSoFar}/{totalToProcess}</p>
          )}
        </div>
        <label htmlFor="from">From</label>
        <DateInput id="from" date={from} onChange={setFrom} />
        <label htmlFor="to">To</label>
        <DateInput id="to" date={to} onChange={setTo} />
        <label htmlFor="period">Period</label>
        <div>
          <select id="period" onChange={(evt) => setPeriod(evt.target.value)} defaultValue={period}>
            <option value="P1D">Daily</option>
            <option value="PT30M">Half-Hourly</option>
          </select>
        </div>
        <label htmlFor="companies">Companies</label>
        <div id="companies">
          {orderedCompanyIds.map(coId => (
            <CompanySelector key={`selector-${coId}`} companyId={coId} sites={allSites} onChange={onCompanySelectorChange} />
          ))}
        </div>
      </div>
    );
  } else if(companiesStatus === 'failed' || sitesStatus === 'failed') {
    content = <Alert color="warning">Companies or sites failed to load</Alert>
  } else {
    content = <LoadingBlock text="About to start loading..." />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          <h2>Extracts</h2>
          {content}
        </Col>
      </Row>
    </Container>
  );
}

export default withAuthenticationRequired(ExtractsPageComponent, {
  onRedirecting: () => <Loading />,
});
