import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCompanyById,
  selectCompanyById
} from "./companySlice";
import { CompanyContentComponent } from "./CompanyContent";

export const CompanyPageComponent = ({ match }) => {

  const { id } = match.params;
  const company = useSelector(state => selectCompanyById(state, id));

  const dispatch = useDispatch();

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if(company == null) {
      dispatch(fetchCompanyById({
        getToken: getAccessTokenSilently,
        id
      }))
    }
  }, [company, id, dispatch, getAccessTokenSilently]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          {company == null && (
            <div>
              <h2><Link to="/companies">Companies</Link></h2>
              <p>Company with ID {id} is loading</p>
            </div>
          )}
          {company != null && (
            <div>
              <h2><Link to="/companies">Companies</Link> &gt; {company.name}</h2>
              <CompanyContentComponent company={company} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(CompanyPageComponent, {
  onRedirecting: () => <Loading />,
});
