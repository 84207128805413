import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const personAdapter = createEntityAdapter({
  selectId: (person) => person._id,
  sortComparer: (a, b) => {
    if(a.name != null && b.name != null) {
      return a.name.localeCompare(b.name)
    } else if(a.name == null && b.name != null) {
      return -1;
    } else if(a.name != null && b.name == null) {
      return 1;
    }
    return 0;
  }
});

const initialState = personAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchPeople = createAsyncThunk(
  'people/fetchAll',
  async ({ getToken }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getPeople(token);
    return response.people;
  }
);

export const fetchPersonById = createAsyncThunk(
  'people/fetchById',
  async ({ getToken, id }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getPersonById(token, id);
    return response;
  }
)

export const fetchProfileByAccountId = createAsyncThunk(
  'people/fetchProfileByAccountId',
  async ({ getToken, accountId}, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getProfileByAccountId(token, accountId);
    return response;
  }
)

export const personSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPeople.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchPeople.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      personAdapter.upsertMany(state, action.payload);
    },
    [fetchPeople.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchPersonById.fulfilled]: personAdapter.addOne
  }
});

export default personSlice.reducer;

export const {
  selectAll: selectAllPeople,
  selectById: selectPersonById,
  selectIds: selectPersonIds
} = personAdapter.getSelectors(state => state.people);
