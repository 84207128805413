import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import {
  selectFeedById
} from "./feedSlice";
import {
  selectReportById
} from "./reportSlice";
import { ReportBlock } from './UploadReport';


export const ReportPageComponent = ({ match }) => {

  const { id, rid } = match.params;
  const feed = useSelector(state => selectFeedById(state, id));
  const report = useSelector(state => selectReportById(state, rid));

  if(report == null) {
    return (
      <Container className="mb-5">
        <Row className="align-items-center profile-header mb-5 text-md-left">
          <Col md>
            <p>Cannot find report with ID ${rid}.</p>
          </Col>
        </Row>
      </Container>
    )
  }

  if(report.feed !== id) {
    return (
      <Container className="mb-5">
        <Row className="align-items-center profile-header mb-5 text-md-left">
          <Col md>
            <p>Report ${rid} is not associated with feed ${id}.</p>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          <div>
            <h2><Link to="/feeds">Feeds</Link> &gt; <Link to={`/feeds/${id}`}>{feed.name}</Link> &gt; {report._id}</h2>
            <ReportBlock report={report} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ReportPageComponent, {
  onRedirecting: () => <Loading />,
});
