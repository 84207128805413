import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const reportAdapter = createEntityAdapter({
  selectId: (report) => report._id,
  sortComparer: (a, b) => new Date(b.startedAt) - new Date(a.startedAt)
});

const initialState = reportAdapter.getInitialState({
  status: {},
  error: {}
})

export const postFileToFeed = createAsyncThunk(
  'reports/postFile',
  async ({ getToken, id, file}, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.postFileToFeed(token, id, file);
    return response;
  }
)

export const fetchReportsByFeed = createAsyncThunk(
  'reports/fetchByFeed',
  async ({ getToken, feed }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const feedId = (typeof(feed) === 'string' ? feed : feed._id);
    const response = await client.getReportsByFeedId(token, feedId);
    return response.reports;
  }
)

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchReportsByFeed.pending]: (state, action) => {
      const feed = action.meta.arg.feed;
      const feedId = (typeof(feed) === 'string' ? feed : feed._id);
      state.status[feedId] = 'loading';
    },
    [fetchReportsByFeed.fulfilled]: (state, action) => {
      const feed = action.meta.arg.feed;
      const feedId = (typeof(feed) === 'string' ? feed : feed._id);
      state.status[feedId] = 'succeeded';
      reportAdapter.upsertMany(state, action.payload);
    },
    [fetchReportsByFeed.rejected]: (state, action) => {
      const feed = action.meta.arg.feed;
      const feedId = (typeof(feed) === 'string' ? feed : feed._id);
      state.status[feedId] = 'failed';
      state.error[feedId] = action.error.message;
    },
    [postFileToFeed.fulfilled]: reportAdapter.addOne
  }
});

export default reportSlice.reducer;

const enrichSelectors = (selectors) => {
  const { selectAll } = selectors;
  const selectByFeed = (state, feed) => {
    const feedId = (typeof(feed) === 'string' ? feed : feed._id);
    return selectAll(state).filter(report => report.feed === feedId);
  }
  return {
    ...selectors,
    selectByFeed
  }
}

export const {
  selectAll: selectAllReports,
  selectById: selectReportById,
  selectIds: selectReportIds,
  selectByFeed: selectReportsByFeed
} = enrichSelectors(reportAdapter.getSelectors(state => state.reports));
