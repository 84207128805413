import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert, Table } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCompanies,
  selectCompanyById,
  selectCompanyIds
} from "./companySlice";
import { TableEntry } from '../../components/TableEntry';
import { ConsentCell } from '../consents/ConsentCell';
import { LoadingBlock } from '../../components/LoadingBlock';

let CompanyRow = ({ id }) => {
  const company = useSelector(state => selectCompanyById(state, id));

  return (
    <tr key={company._id}>
      <td>
        <Link to={`/companies/${company._id}`}><TableEntry value={company.name}/></Link>
      </td>
      <td><ConsentCell consent={company?.consent?.loa} /></td>
    </tr>
  )
}

export const CompanyListComponent = () => {
  const dispatch = useDispatch();
  const orderedCompanyIds = useSelector(selectCompanyIds);

  const companiesStatus = useSelector(state => state.companies.status);
  const error = useSelector(state => state.companies.error);

  const {
    getAccessTokenSilently
  } = useAuth0();


  useEffect(() => {
    if(companiesStatus === 'idle') {
      dispatch(fetchCompanies({
        getToken: getAccessTokenSilently
      }))
    }
  }, [companiesStatus, dispatch, getAccessTokenSilently]);

  let content;

  if(companiesStatus === 'loading') {
    content = <LoadingBlock />
  } else if(companiesStatus === 'succeeded') {
    content = (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Letter of Authority</th>
          </tr>
        </thead>
        <tbody>
        {orderedCompanyIds.map((id) => (
          <CompanyRow key={id} id={id} />
        ))}
        </tbody>
      </Table>
    );
  } else if(companiesStatus === 'failed') {
    content = <Alert color="warning">{JSON.stringify(error)}</Alert>
  } else {
    content = <LoadingBlock text="About to start loading..." />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          <h2>Companies</h2>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(CompanyListComponent, {
  onRedirecting: () => <Loading />,
});
