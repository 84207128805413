import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPersonById,
  selectPersonById
} from "./personSlice";
import { AccessLevelBlock } from "./AccessLevelBlock";
import { JobFunctionBlock } from "./JobFunctionBlock";
import { AccountBlock } from "./AccountBlock";

export const PersonPageComponent = ({ match }) => {

  const { id } = match.params;
  const person = useSelector(state => selectPersonById(state, id));

  const dispatch = useDispatch();

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if(person == null) {
      dispatch(fetchPersonById({
        getToken: getAccessTokenSilently,
        id
      }))
    }
  }, [person, id, dispatch, getAccessTokenSilently]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          {person == null && (
            <div>
              <h2><Link to="/people">People</Link></h2>
              <p>Person with ID {id} is loading</p>
            </div>
          )}
          {person != null && (
            <div>
              <h2><Link to="/people">People</Link> &gt; {person.name}</h2>
              <div className="entity">
                <label htmlFor="person-name">Name</label>
                <p id="person-name">{person.name}</p>
                <label htmlFor="person-access-level">Access Level</label>
                <AccessLevelBlock access_level={person.access_level} />
                <label htmlFor="person-job-function">Job Function</label>
                <JobFunctionBlock job_function={person.job_function} />
                <label htmlFor="person-company">Company</label>
                <p id="person-company">{person.company.name}</p>
                {person.account && (
                  <React.Fragment>
                    <label htmlFor="person-job-function">Account</label>
                    <AccountBlock account={person.account} />
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(PersonPageComponent, {
  onRedirecting: () => <Loading />,
});
