import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert, Table, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMeters,
  selectMeterById,
  selectMeterIds,
  selectAllMeters,
  patchMeterById
} from "./meterSlice";
import { TableEntry } from '../../components/TableEntry';
import { LoadingBlock } from '../../components/LoadingBlock';
import { MeterIdentifierList } from './MeterIdentifierList';

let MeterRow = ({ id }) => {
  const meter = useSelector(state => selectMeterById(state, id));

  return (
    <tr key={meter._id}>
      <td>
        <Link to={`/meters/${meter._id}`}><TableEntry value={meter.nickname}/></Link>
      </td>
      <td>
        <MeterIdentifierList identifiers={meter.identifiers} />
      </td>
      <td><TableEntry value={meter?.connection_type}/></td>
      <td><TableEntry value={meter?.supply}/></td>
      <td><TableEntry value={meter?.provider}/></td>
      <td>{meter?.incident && meter.incident.state ? '⚠' : ''}</td>
    </tr>
  )
}

export const MeterListComponent = () => {
  const dispatch = useDispatch();
  const orderedMeterIds = useSelector(selectMeterIds);

  const findMetersWithInvalidIdentifierKeys = (meters) => meters.filter(meter => {
    const identifiers = meter.identifiers || [];
    const identifiersWithInvalidKeys = identifiers.filter(
      id => id._key !== `${id.scheme}:${id.value}`
    )
    return (identifiersWithInvalidKeys.length > 0)
  });

  // Check meter identifier keys
  const allMeters = useSelector(selectAllMeters);
  const metersWithKeyIssues = findMetersWithInvalidIdentifierKeys(allMeters);

  const metersStatus = useSelector(state => state.meters.status);
  const error = useSelector(state => state.meters.error);

  const [isFixing, setIsFixing] = useState(false);
  const [fixedSoFar, setFixedSoFar] = useState(0);
  const [totalToFix, setTotalToFix] = useState(0);

  const {
    getAccessTokenSilently
  } = useAuth0();


  useEffect(() => {
    if(metersStatus === 'idle') {
      dispatch(fetchMeters({
        getToken: getAccessTokenSilently
      })).then(
        action => {
          const { payload } = action;
          const metersToFix = findMetersWithInvalidIdentifierKeys(payload || []);
          setTotalToFix(metersToFix.length);
          return action;
        }
      )
    }
  }, [metersStatus, dispatch, getAccessTokenSilently]);

  const onFixItButtonClicked = () => {
    const metersToFix = [...metersWithKeyIssues];
    setTotalToFix(metersToFix.length);
    setFixedSoFar(0);
    setIsFixing(true);

    const fixAll = async (remaining, fixed) => {
      setFixedSoFar(fixed.length);
      if (remaining.length === 0) {
        return fixed;
      }
      const site = remaining[0];
      const siteId = site._id;
      const tailRemaining = remaining.slice(1);
      const { identifiers } = site;
      const fixedIdentifiers = identifiers.map(id => ({
        ...id,
        _key: `${id.scheme}:${id.value}`
      }))
      return dispatch(patchMeterById({
        getToken: getAccessTokenSilently,
        id: siteId,
        patch: {
          identifiers: fixedIdentifiers
        }
      })).then(
        result => {
          const newFixed = [...fixed, result];
          return fixAll(tailRemaining, newFixed);
        },
        err => {
          console.log(`Caught error for ${siteId}`);
          console.dir(err)
          return fixAll(tailRemaining, fixed);
        }
      )
    }

    return fixAll(metersToFix, []).then(result => {
      setIsFixing(false);
      return result;
    });
  }

  let content;

  if(metersStatus === 'loading') {
    content = <LoadingBlock />
  } else if(metersStatus === 'succeeded') {
    content = (
      <div>
        <div className="inputrow">
          <p>Meters with identifier key issues: {metersWithKeyIssues.length}/{allMeters.length}</p>
          <Button
            color="primary"
            disabled={metersWithKeyIssues.length === 0 || isFixing === true}
            onClick={onFixItButtonClicked}
          >Fix it!</Button>
          <p>{fixedSoFar}/{totalToFix}</p>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Nickname</th>
              <th>Identifiers</th>
              <th>Connection Type</th>
              <th>Supply</th>
              <th>Provider</th>
              <th>Incident</th>
            </tr>
          </thead>
          <tbody>
          {orderedMeterIds.map((id) => (
            <MeterRow key={id} id={id} />
          ))}
          </tbody>
        </Table>
      </div>
    );
  } else if(metersStatus === 'failed') {
    content = <Alert color="warning">{JSON.stringify(error)}</Alert>
  } else {
    content = <LoadingBlock text="About to start loading..." />
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          <h2>Meters</h2>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MeterListComponent, {
  onRedirecting: () => <Loading />,
});
