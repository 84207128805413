const contentData = [
  {
    title: "Check home",
    link: "/",
    description:
      "Go to the home of the admin app"
  }
];

export default contentData;
