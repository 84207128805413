export const ConsentCell = ({ consent }) => {
  if(consent == null) {
    return (
      <span className="table-entry">🔶</span>
    )
  }

  const status = consent.status;

  if(status == null) {
    return (
      <span className="table-entry">No status</span>
    )
  }

  const lastStatus = consent.status.reduce((acc, s) => {
    if(acc == null || s.updated_at > acc.updated_at) {
      return s;
    }
    return acc;
  })

  if(lastStatus == null) {
    return (
      <span className="table-entry">No status entries</span>
    )
  }

  return (
    <span className="table-entry">{lastStatus.status}</span>
  )
}
