import {
  //createSlice,
  createAsyncThunk,
  //createEntityAdapter
} from '@reduxjs/toolkit';

export const fetchDocument = createAsyncThunk(
  'documents/fetchByReference',
  async ({ getToken, reference, onFileReceived }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getDocument(token, reference);
    const contentType = response.headers.get('Content-Type');
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    if(onFileReceived != null) {
      onFileReceived(url);
    }
    URL.revokeObjectURL(url);
    return {
      bucket: reference.bucket,
      key: reference.key,
      contentType
    };
  }
);

export const listDocuments = createAsyncThunk(
  'documents/listDocuments',
  async ({ getToken, bucket, continuation }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.listDocuments(token, bucket, continuation);
    return response;
  }
)

export const processDocument = createAsyncThunk(
  'documents/processDocument',
  async ({ getToken, document }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const { bucket, key } = document;
    const response = await client.processDocument(token, bucket, key);
    return response;
  }
)
