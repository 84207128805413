export const AccessLevelBlock = ({ access_level }) => {
  const defaultJobFunction = access_level.default_job_function;
  let jobFunctionName = 'None';
  if(defaultJobFunction != null) {
    if(defaultJobFunction._ref != null) {
      jobFunctionName = defaultJobFunction._ref
    } else {
      jobFunctionName = defaultJobFunction.title
    }
  }
  let capabilities = 'None';
  if(access_level.capabilities && access_level.capabilities.length > 0) {
    capabilities = access_level.capabilities.join(', ');
  }
  return (
    <div className="entity">
      <label htmlFor="access-level-title">Title</label>
      <p id="access-level-title">{access_level.title}</p>
      <label htmlFor="access-level-slug">Slug</label>
      <p id="access-level-slug">{access_level.slug.current}</p>
      <label htmlFor="access-level-capabilities">Capabilities</label>
      <p id="access-level-capabilities">{capabilities}</p>
      <label htmlFor="access-level-default-job-function">Default Job Function</label>
      <p id="access-level-default-job-function">{jobFunctionName}</p>
    </div>
  )
}
