import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const siteAdapter = createEntityAdapter({
  selectId: (site) => site._id,
  sortComparer: (a, b) => {
    if(a.name != null && b.name != null) {
      return a.name.localeCompare(b.name)
    } else if(a.name == null && b.name != null) {
      return -1;
    } else if(a.name != null && b.name == null) {
      return 1;
    }
    return 0;
  }
});

const initialState = siteAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchSites = createAsyncThunk(
  'sites/fetchAll',
  async ({ getToken }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getSites(token);
    return response.sites;
  }
);

export const fetchSiteById = createAsyncThunk(
  'sites/fetchById',
  async ({ getToken, id }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getSiteById(token, id);
    return response;
  }
)

export const fetchSliceBySiteAndSupplyId = createAsyncThunk(
  'sites/fetchSliceByIdAndSupplyId',
  async({ getToken, siteId, supplyId, from, to, period }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getSliceBySiteAndSupplyId(token, siteId, supplyId, from, to, period);
    return response;
  }
)

export const siteSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSites.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSites.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      siteAdapter.upsertMany(state, action.payload);
    },
    [fetchSites.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchSiteById.fulfilled]: siteAdapter.addOne
  }
});

export default siteSlice.reducer;

export const {
  selectAll: selectAllSites,
  selectById: selectSiteById,
  selectIds: selectSiteIds
} = siteAdapter.getSelectors(state => state.sites);
