export const JobFunctionBlock = ({ job_function }) => {
  return (
    <div className="entity">
      <label htmlFor="access-level-title">Title</label>
      <p id="access-level-title">{job_function.title}</p>
      <label htmlFor="access-level-slug">Slug</label>
      <p id="access-level-slug">{job_function.slug.current}</p>
    </div>
  )
}
