import React from "react";

export const MeterIdentifierList = ({ identifiers, showKey }) => {
  const identifierSchemes = {
    mpan: 'MPAN',
    meter: 'Meter number',
    internal: 'Internal',
    str: 'STR',
    'gridduck:asset': 'GridDuck'
  }

  if(identifiers == null) {
    return (
      <p>No identifiers</p>
    )
  }
  
  return (
    <div className="entity">
    {identifiers.map((identifier) => (
      <React.Fragment key={`meter-identifier-${identifier.scheme}`}>
        <label htmlFor={`meter-identifier-${identifier.scheme}`}>{identifierSchemes[identifier.scheme] || identifier.scheme}</label>
        <p id={`meter-identifier-${identifier.scheme}`}>{identifier.value}{showKey === true && (<span> ({identifier._key})</span>)}</p>
      </React.Fragment>
    ))}
    </div>
  )
}
