import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Table } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMeterById,
  selectMeterById,
  fetchSliceByIdAndMetric
} from "./meterSlice";
import { MeterIdentifierList } from "./MeterIdentifierList";
import { DataChart } from './DataChart';


const TimeseriesBlock = ({ timeseries, metric, meter }) => {
  if(timeseries == null) {
    return (
      <p>No timeseries</p>
    )
  }

  const primaryMeterIdentifer = meter.identifiers[0];
  const meterId = `${primaryMeterIdentifer.scheme}:${primaryMeterIdentifer.value}`;
  const metricId = metric.label;
  const fetchDataSlice = (getToken, from, to, period) => fetchSliceByIdAndMetric({
    getToken,
    meterId,
    metricId,
    from,
    to,
    period
  });
  const findSeriesIndex = () => 0;

  return (
    <div className="graph-container">
      <div className="entity">
        <label htmlFor={`${timeseries._id}-measure`}>Measure</label>
        <p id={`${timeseries._id}-measure`}>{timeseries.measure}</p>
        <label htmlFor={`${timeseries._id}-unit`}>Unit</label>
        <p id={`${timeseries._id}-unit`}>{timeseries.unit}</p>
        <label htmlFor={`${timeseries._id}-source`}>Source</label>
        <p id={`${timeseries._id}-source`}>{timeseries.source}</p>
      </div>
      <DataChart fetchDataSlice={fetchDataSlice} findSeriesIndex={findSeriesIndex} />
    </div>
  )
}

const MetricRow = ({ metric, meter }) => {
  return (
    <tr>
      <td>{metric.label}</td>
      <td>{metric.measure}</td>
      <td>{metric.unit}</td>
      <td>{metric.period}</td>
      <td><TimeseriesBlock timeseries={metric.ts} metric={metric} meter={meter} /></td>
    </tr>
  )
}

export const MeterPageComponent = ({ match }) => {

  const { id } = match.params;
  const meter = useSelector(state => selectMeterById(state, id));

  const dispatch = useDispatch();

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if(meter == null) {
      dispatch(fetchMeterById({
        getToken: getAccessTokenSilently,
        id
      }))
    }
  }, [meter, id, dispatch, getAccessTokenSilently]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          {meter == null && (
            <div>
              <h2><Link to="/meters">Meters</Link></h2>
              <p>Meter with ID {id} is loading</p>
            </div>
          )}
          {meter != null && (
            <div>
              <h2><Link to="/meters">Meters</Link> &gt; {meter.nickname || meter.identifiers[0].value}</h2>
              <div className="entity">
                <label htmlFor="meter-nickname">Nickname</label>
                <p id="meter-nickname">{meter.nickname}</p>
                <label htmlFor="meter-identifiers">Identifiers</label>
                <MeterIdentifierList identifiers={meter.identifiers} id="meter-identifiers" />
                <label htmlFor="meter-metrics">Metrics</label>
                {meter.metrics && meter.metrics.length > 0 && (
                <Table id="meter-metrics">
                  <thead>
                    <tr><th>Label</th><th>Measure</th><th>Unit</th><th>Period</th><th>Timeseries</th></tr>
                  </thead>
                  <tbody>
                    {meter.metrics.map(metric => (
                      <MetricRow key={metric.label} metric={metric} meter={meter} />
                    ))}
                  </tbody>
                </Table>
                )}
                {(!meter.metrics || meter.metrics.length <= 0) && (
                  <p id="meter-metrics">None</p>
                )}
                <label htmlFor="meter-connection-type">Connection Type</label>
                <p id="meter-connection-type">{meter?.connection_type}</p>
                <label htmlFor="meter-supply">Supply</label>
                <p id="meter-supply">{meter?.supply}</p>
                <label htmlFor="meter-provider">Provider</label>
                <p id="meter-provider">{meter?.provider}</p>
                <label htmlFor="meter-incident">Incident</label>
                {meter.incident && meter.incident.state && (
                  <div className="entity" id="meter-incident">
                    <label htmlFor="meter-incident-state">State</label>
                    <p>{meter.incident.state}</p>
                    <label htmlFor="meter-incident-start-date">Start date</label>
                    <p>{meter.incident.start_date}</p>
                  </div>
                )}
                {(!meter.incident || !meter.incident.state) && (
                  <p id="meter-incident">None</p>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MeterPageComponent, {
  onRedirecting: () => <Loading />,
});
