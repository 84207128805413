import React from "react";

export const LocationComponent = ({ location }) => {
  if(location == null) {
    return (
      <p>Unknown</p>
    );
  }

  return (
    <div className="entity">
      <label htmlFor="location-name">Name</label>
      <p id="location-name">{location.display_name}</p>
      <label htmlFor="location-city">City</label>
      <p id="location-city">{location.parent_city?.name}</p>
      <label htmlFor="location-admin-area">Administrative Area</label>
      <p id="location-admin-area">{location.admin_area?.name}</p>
      <label htmlFor="location-country">Country</label>
      <p id="location-country">{location.country?.name}</p>
      <label htmlFor="location-region">Region</label>
      <p id="location-region">{location.region?.name}</p>
    </div>
  )
}
