import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

const companyAdapter = createEntityAdapter({
  selectId: (company) => company._id,
  sortComparer: (a, b) => {
    if(a.name != null && b.name != null) {
      return a.name.localeCompare(b.name)
    } else if(a.name == null && b.name != null) {
      return -1;
    } else if(a.name != null && b.name == null) {
      return 1;
    }
    return 0;
  }
});

const initialState = companyAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchCompanies = createAsyncThunk(
  'companies/fetchAll',
  async ({ getToken }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getCompanies(token);
    return response.companies;
  }
);

export const fetchCompanyById = createAsyncThunk(
  'companies/fetchById',
  async ({ getToken, id }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    const response = await client.getCompanyById(token, id);
    return response;
  }
)

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompanies.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchCompanies.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      companyAdapter.upsertMany(state, action.payload);
    },
    [fetchCompanies.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [fetchCompanyById.fulfilled]: companyAdapter.addOne
  }
});

export default companySlice.reducer;

export const {
  selectAll: selectAllCompanies,
  selectById: selectCompanyById,
  selectIds: selectCompanyIds
} = companyAdapter.getSelectors(state => state.companies);
