import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { Container, Row, Col } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSiteById,
  selectSiteById
} from "./siteSlice";
import { SiteContentComponent } from './SiteContent';

export const SitePageComponent = ({ match }) => {

  const { id } = match.params;
  const site = useSelector(state => selectSiteById(state, id));

  const dispatch = useDispatch();

  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if(site == null) {
      dispatch(fetchSiteById({
        getToken: getAccessTokenSilently,
        id
      }))
    }
  }, [site, id, dispatch, getAccessTokenSilently]);

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-md-left">
        <Col md>
          {site == null && (
            <div>
              <h2><Link to="/sites">Sites</Link></h2>
              <p>Site with ID {id} is loading</p>
            </div>
          )}
          {site != null && (
            <div>
              <h2><Link to="/sites">Sites</Link> &gt; {site.name}</h2>
              <SiteContentComponent site={site} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(SitePageComponent, {
  onRedirecting: () => <Loading />,
});
