import React from "react";
import { Link } from 'react-router-dom';
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import {
  selectMeterById,
} from '../meters/meterSlice';
import {
  fetchSliceBySiteAndSupplyId
} from './siteSlice';

import { LocationComponent } from './Location';
import { TableEntry } from '../../components/TableEntry';
import { MeterIdentifierList } from "../meters/MeterIdentifierList";
import { DataChart } from "../meters/DataChart";

const MeterRow = ({ id }) => {
  const meter = useSelector(state => selectMeterById(state, id));

  if (meter == null) {
    return (
      <tr><td colSpan="3">Not loaded</td></tr>
    )
  }

  return (
    <tr key={meter._id}>
      <td>
        <Link to={`/meters/${meter._id}`}>
          <MeterIdentifierList identifiers={meter.identifiers} showKey={true} />
        </Link>
      </td>
      <td><TableEntry value={meter?.connection_type}/></td>
    </tr>
  )
}

const findSeriesIndex = (seriesName) => (slice) => Math.max(0, slice.n.findIndex(n => n === seriesName));
const fetchDataSlice = (siteId, supplyId) => (getToken, from, to, period) => fetchSliceBySiteAndSupplyId({
  getToken,
  siteId,
  supplyId,
  from,
  to,
  period
});

const SupplyContent = ({ site, supply, supplyKey }) => {
  const idPrefix = `site-supply-${supplyKey}`;
  const supplyMetric = (supplyKey === 'occupancy') ? 'occupancy' : 'energy';
  const seriesName = `site:${site._id}/${supplyKey}/${supplyMetric}`;

  return (
    <div className="graph-container" id={idPrefix}>
      <Table id={`${idPrefix}-meters`}>
        <thead>
          <tr><th>Identifiers</th><th>Connection Type</th></tr>
        </thead>
        <tbody>
          {supply.meters != null && supply.meters.map(meter => (
            <MeterRow key={meter._id || meter._ref} id={meter._id || meter._ref} />
          ))}
          {(supply.meters == null || supply.meters.length === 0) && (
            <tr><td colSpan="2">None</td></tr>
          )}
        </tbody>
      </Table>
      <DataChart
        fetchDataSlice={fetchDataSlice(site._id, supplyKey)}
        findSeriesIndex={findSeriesIndex(seriesName)}
      />
    </div>
  )
}

export const SiteContentComponent = ({ site }) => {
  if(site == null) {
    return null;
  }

  const supplyKeys = Object.keys(site.supplies);
  const suppliesToDisplay = supplyKeys.filter(k => ['electricity', 'gas', 'occupancy'].includes(k));
  const seriesName = `site:${site._id}/energy/energy`;

  return (
    <div className="entity">
      <label htmlFor="site-name">Name</label>
      <p id="site-name">{site.name}</p>
      <label htmlFor="site-type">Type</label>
      <p id="site-type">{site.type?.title}</p>
      <label htmlFor="site-location">Location</label>
      <LocationComponent id="site-location" location={site.location} />
      <label htmlFor="site-company">Company</label>
      {site.company != null && (
        <Link id="site-company" to={`/companies/${site.company._id}`}>{site.company.name || '🔶'}</Link>
      )}
      {site.company == null && (
        <p id="site-company">None</p>
      )}
      <label htmlFor="site-supplies">Supplies</label>
      {suppliesToDisplay.length === 0 && (
        <p id="site-supplies">None</p>
      )}
      {suppliesToDisplay.length > 0 && (
        <div className="entity" id="site-supplies">
          {suppliesToDisplay.includes('electricity') && (
            <React.Fragment>
              <label htmlFor="site-supply-electricity">Electricity</label>
              <SupplyContent site={site} supply={site.supplies.electricity} supplyKey="electricity" />
            </React.Fragment>
          )}
          {suppliesToDisplay.includes('gas') && (
            <React.Fragment>
              <label htmlFor="site-supply-gas">Gas</label>
              <SupplyContent site={site} supply={site.supplies.gas} supplyKey="gas" />
            </React.Fragment>
          )}
          {suppliesToDisplay.includes('occupancy') && (
            <React.Fragment>
              <label htmlFor="site-supply-occupancy">Occupancy</label>
              <SupplyContent site={site} supply={site.supplies.occupancy} supplyKey="occupancy" />
            </React.Fragment>
          )}
        </div>
      )}
      <label htmlFor="site-energy-chart">Energy Chart</label>
      <div className="graph-container">
        <DataChart
          id="site-energy-chart"
          fetchDataSlice={fetchDataSlice(site._id, '*')}
          findSeriesIndex={findSeriesIndex(seriesName)}
        />
      </div>
    </div>
  )
}
