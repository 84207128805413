import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

const initialState = {
  slice: null,
  status: 'idle',
  error: null
}

export const fetchSliceBySiteIdAndSelector = createAsyncThunk(
  'extracts/fetchSliceBySiteIdAndSelector',
  async ({ getToken, siteId, selector, from, to, period }, { extra }) => {
    const { client } = extra;
    const token = await getToken();
    try {
      const response = await client.getSliceBySiteAndSelector(token, siteId, selector, from, to, period);
      return {
        siteId,
        slice: response
      };
    } catch (e) {
      if (e.message !== 'Not Found') {
        console.log(`Finished ${siteId} with error: ${e.name}`);
        console.dir(e);
      }
      return {
        siteId,
        error: e.message
      }
    }
  }
)

export const extractsSlice = createSlice({
  name: 'extracts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSliceBySiteIdAndSelector.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSliceBySiteIdAndSelector.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // const frame = new DataFrame(action.payload).filter(
      //   series => {
      //     const path = series.path();
      //     return path.length > 1 && path[1] === 'energy'
      //   }
      // )
      // if (state.slice == null) {
      //   state.slice = frame.slice;
      // } else {
      //   const currentFrame = new DataFrame(state.slice)
      //   const newFrame = currentFrame.merge(frame);
      //   state.slice = newFrame.slice
      // }
    },
    [fetchSliceBySiteIdAndSelector.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    }
  }
});

export default extractsSlice.reducer;
