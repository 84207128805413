import React, { useState } from "react";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";


export const DataChart = ({ fetchDataSlice, findSeriesIndex }) => {
  const toDate = new Date();
  toDate.setUTCHours(0);
  toDate.setUTCMinutes(0);
  toDate.setUTCSeconds(0);
  toDate.setUTCMilliseconds(0);
  const to = toDate.toISOString().slice(0, 10);
  const fromDate = new Date(toDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  const from = fromDate.toISOString().slice(0, 10);
  const period = 'P1D';

  const [slice, setSlice] = useState(null);
  const [error, setError] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);

  const dispatch = useDispatch();
  const {
    getAccessTokenSilently
  } = useAuth0();

  const fetchSlice = () => {
    setIsLoading(true);
    dispatch(fetchDataSlice(
      getAccessTokenSilently,
      from,
      to,
      period
    )).then(
      action => {
        //console.dir(action);
        if (action.error != null) {
          setError(action.error.message)
        } else {
          setSlice(action.payload);
        }
        setIsLoading(false);
        return action;
      }
    )
  }

  if(error != null) {
    return (
      <div className="chart">
        <p>Could not load chart: {error}</p>
      </div>
    )
  }

  if(slice == null) {
    return (
      <div className="chart">
        <Button
          color="primary"
          disabled={isLoading}
          onClick={() => fetchSlice()}
        >Load Chart</Button>
      </div>
    )
  }

  const recordLabels = slice.r
    .map(rr => rr.ts)
    .map(l => l.slice(8, 10));
  while(recordLabels.length < 7) {
    recordLabels.push('?')
  }
  const seriesIndex = findSeriesIndex(slice);
  const recordValues = slice.r
    .map(rr => rr.v[seriesIndex]);
  while(recordValues.length < 7) {
    recordValues.push(null)
  }
  const displayWidth = 280;
  const displayHeight = displayWidth/2;
  const maxRecord = recordValues.reduce((a, b) => b>a?b:a, 0);
  const barHeight = 6*displayHeight/7;
  const barScale = maxRecord > 0 ? barHeight / maxRecord : 1;
  const barWidth = displayWidth / recordValues.length;
  const labelHeight = displayHeight - barHeight;
  return (
    <svg className="chart" viewBox={`0 0 ${displayWidth} ${displayHeight}`}>
      {recordLabels.map((label, i) => (
        <g key={`label-${i}`}>
          {recordValues[i] == null && (
            <rect x={i*barWidth} y={barHeight} width={barWidth} height={labelHeight} stroke="none" fill="#fc0"/>
          )}
          <text x={(i+0.5)*barWidth} y={barHeight+0.8*labelHeight} textAnchor="middle">{label}</text>
        </g>
      ))}
      {recordValues.map((value, i) => (
        <rect key={`bar-${i}`} x={i*barWidth} y={displayHeight-labelHeight-value*barScale} width={barWidth} height={value*barScale} fill="#f0c" />
      ))}
      <line x1="0" y1={barHeight} x2="280" y2={barHeight} stroke="black" />
    </svg>
  )
}
