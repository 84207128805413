export const ElapsedTime = ({ from, to }) => {
  const dateParts = (d) => {
    return [
      d.getMilliseconds(),
      d.getSeconds(),
      d.getMinutes(),
      d.getHours(),
      d.getDate(),
      d.getMonth(),
      d.getFullYear()
    ]
  }

  const dateDiff = (fp, tp) => {
    return fp.map((p, i) => tp[i] - p);
  }

  const normaliseDateDiff = (d) => {
    // This is approximative for days in month
    const partMax = [1000, 60, 60, 24, 30, 12]
    const reduced = d.reduce((acc, p, i) => {
      const adjp = p + acc.r;
      if(adjp < 0 && i < partMax.length) {
        acc.r = -1;
        acc.p.push(partMax[i]+adjp)
      } else {
        acc.r = 0;
        acc.p.push(adjp);
      }
      return acc;
    }, {r: 0, p: []})
    return reduced.p;
  }

  const formatDiff = (d) => {
    const labels = ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']
    const rd = d.reverse();
    const tparts = rd.reduce((acc, p, i) => {
      if(p !== 0) {
        return [...acc, `${p} ${labels[i]}`]
      }
      return acc
    }, [])
    return tparts.join(', ');
  }

  const text = formatDiff(
    normaliseDateDiff(
      dateDiff(
        dateParts(new Date(from)),
        dateParts(new Date(to))
      )
    )
  )

  return (
    <span>{text}</span>
  )
}