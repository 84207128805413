import React from "react";
import { Table } from "reactstrap";
import { DocumentDownloadButton } from "../documents/DocumentDownloadButton";

export const ConsentContentComponent = ({ consent }) => {
  if(consent == null) {
    return null;
  }

  let statusContent = (
    <p>No status</p>
  )

  const status = consent.status;

  if(status != null && status.length > 0) {
    statusContent = (
      <Table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Reason</th>
            <th>Updated At</th>
            <th>Documents</th>
          </tr>
        </thead>
        <tbody>
        {status.map((s, i) => (
          <tr key={s.updated_at}>
            <td>{s.status}</td>
            <td>{s.reason}</td>
            <td>{s.updated_at}</td>
            <td>
              {s.documents && s.documents.map((d, j) => (
                <DocumentDownloadButton key={`doc-${i}-${j}`} reference={d} label={j+1} />
              ))}
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    )
  }

  let extraContent = null;

  if(consent._type === 'bill_consent') {
    extraContent = (
      <React.Fagment>
        <label htmlFor="consent-meter-number">Meter Number</label>
        <p>{consent.meter_number}</p>
      </React.Fagment>
    )
  }

  if(consent._type === 'loa_consent') {
    extraContent = (
      <React.Fragment>
        <label htmlFor="consent-company-name">Company Name</label>
        <p id="consent-company-name">{consent.company_name}</p>
        <label htmlFor="consent-company-address">Company Address</label>
        <p id="consent-company-address">{[
          consent.company_premises,
          consent.company_address1,
          consent.company_address2,
          consent.company_locality,
          consent.company_region,
          consent.company_postcode].filter(v => v != null && v.trim() !== '').join(', ')
        }</p>
        <label htmlFor="consent-company-number">Company Number</label>
        <p id="consent-company-number">{consent.company_number}</p>
        <label htmlFor="consent-contact">Contact Details</label>
        <p id="consent-contact">{[
          consent.contact_name,
          consent.contact_position,
          consent.contact_email,
          consent.contact_telephone].filter(v => v != null && v.trim() !== '').join(', ')
        }</p>
      </React.Fragment>
    )
  }

  return (
    <div className="entity">
      <label htmlFor="consent-status">Status History</label>
      {statusContent}
      {extraContent}
    </div>
  )
}
